import { Box } from '@mui/material'
import { useEffect, useMemo } from 'react'
import dompurify from 'dompurify'
import parse from 'html-react-parser'

interface IProps {
  html: string | undefined
  callback?: any
}
export const RenderHTML = ({ html, callback }: IProps) => {
  const memoizedHtml = useMemo(() => {
    const changedHtml = parse(
      dompurify.sanitize(html ?? '', { FORCE_BODY: true })
    )
    return changedHtml
  }, [html])

  useEffect(() => {
    callback && callback()
  }, [memoizedHtml])

  return (
    <Box
      className={'htmlBox'}
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      {memoizedHtml}
    </Box>
  )
}

import { Autocomplete, Box, IconButton, TextField } from '@mui/material'
import plus_icon from 'assets/img/add.svg'
import { FocusEventHandler, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { EmailFieldMenuItem } from './EmailFieldMenuItem'

const hasSafariProblemWithAutoFocus = () => {
  const userAgent = navigator.userAgent
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)

  if (!isSafari) return false
  const safariVersionMatch = userAgent.match(/Version\/(\d+)/)
  const safariVersion =
    safariVersionMatch && safariVersionMatch.length >= 2
      ? parseInt(safariVersionMatch[1], 10)
      : 0
  return isSafari && safariVersion < 17
}

const isAutoFocusSupported = !hasSafariProblemWithAutoFocus()

export const EmailFieldWithMenu = ({
  addEmailToList,
  input,
  options,
  isHideOnBlur,
  className,
  onBlurCallback,
  placeholder,
}: {
  addEmailToList: (email: string) => boolean
  input?: {
    inputValue: string
    setInputValue: (value: string) => void
  }
  options: string[]
  isHideOnBlur?: boolean
  className?: string
  onBlurCallback?: ((e) => void) | any
  placeholder?: string
}) => {
  const { user } = useSelector((state: RootState) => state.user, shallowEqual)
  const [isEmailInputHidden, setIsEmailInputHidden] = useState(false)

  const [localInputValue, setLocalInputValue] = useState('')

  const inputValue = input?.inputValue ?? localInputValue
  const setInputValue = input?.setInputValue ?? setLocalInputValue

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleInputBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    onBlurCallback(e)
    const added = addEmailToList(e.target.value)
    setIsEmailInputHidden(true)
    if (added) setInputValue('')
  }

  const handleInputKeyDown = (evt) => {
    if (['Enter', ',', ';'].includes(evt.key)) {
      evt.preventDefault()
      if (addEmailToList(inputValue)) setInputValue('')
    }
  }

  const handleChange = (e, value) => {
    if (!value) setInputValue('')
    if (addEmailToList(value)) setInputValue('')
  }

  if (isHideOnBlur && isEmailInputHidden) {
    return (
      <IconButton
        color="secondary"
        onClick={() => {
          setIsEmailInputHidden(false)
        }}
        sx={{
          p: { xs: '4px' },
          borderRadius: '4px',
          height: 'min-content',
          display: 'flex',
          '&:focus': {
            backgroundColor: '#F7F7F7',
          },
        }}
      >
        <Box component={'img'} height={16} width={16} src={plus_icon} alt="" />
      </IconButton>
    )
  }

  return (
    <Autocomplete
      className={className}
      disablePortal
      disableClearable
      freeSolo
      componentsProps={{
        paper: {
          sx: {
            mt: '4px',
            boxShadow:
              '0px 12px 24px 0px rgba(150, 150, 150, 0.24), 0px 0px 1px 0px rgba(0, 0, 0, 0.32)',
            backgroundColor: '#fff',
            minWidth: '280px',
          },
        },
      }}
      options={options}
      fullWidth
      onChange={handleChange}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            sx: {
              minWidth: '160px',
              height: '24px',
            },
          }}
          inputProps={{
            ...params.inputProps,
            sx: {
              p: '0 !important',
            },
          }}
          sx={{ alignSelf: 'baseline', pt: '1px' }}
          placeholder={placeholder}
          variant="standard"
          size="small"
          autoFocus={isAutoFocusSupported}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
        />
      )}
      renderOption={(props, option) => (
        <EmailFieldMenuItem
          {...props}
          key={option}
          email={option}
          {...(user.email == option ? { optionalText: '(Вы)' } : null)}
          onClick={() => {
            addEmailToList(option)
          }}
        />
      )}
    />
  )
}
